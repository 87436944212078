import React from 'react'
import {Link} from 'gatsby'
import { FaArrowRight } from 'react-icons/fa'
import {isUserInRegion} from '../services/auth'
import FileIcon from './fileIcon'
import {sanitizeFilename} from '../lib/helpers'
import FileComponent from './fileComponent'

const MainReference = ({items, userRegion}) => {
    let filteredItem = items.filter(item => isUserInRegion(userRegion, item.region))

    return (
        <ul className="list-group list-group-borderless list-group-icon-primary-bg me-md-6">
            {
                filteredItem &&
                filteredItem.map((item, i) => (
                    <li key={i} className="list-group-item">
                        {
                            item._type === 'link' ? 
                            <><FaArrowRight size={22} /> <Link to={item.url || '#'}>{item.title}</Link></> 
                            : <FileComponent item={item} />
                        }
                    </li>
                ))
            }
        </ul>
    )
}

export default MainReference