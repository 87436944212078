import React, {useState, useEffect} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layoutSecure'
import PortableText from '../components/portableText'
import MainReference from '../components/mainReference'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Accordion from '../components/accordion'
import { useSelector, useDispatch } from "react-redux"


export const query = graphql`
    query BuildingPageQuery {
        building: sanityBuildingLanding(_id: { regex: "/(drafts.|)buildingLanding/" }) {
            _id
            banner {
                asset {
                    url
                    _id
                }
                ...SanityImage
                alt
            }
            title
            pageImage {
                ...SanityImage
                alt
            }
            subTitle
            _rawPageDescription(resolveReferences: {maxDepth: 5})
            mainReferences {
                ...SanityFile
                ...SanityLink
            }


            categories {
                title
                _rawDecription(resolveReferences: {maxDepth: 5})
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ...SanityLink
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }

                    ... on SanityLightboxWithDetailPage {
                        _key
                        _type
                        _rawDecription(resolveReferences: {maxDepth: 5})
                        title
                        subTitle
                        sectionLinks {
                          ...SanityFile
                          ...SanityLink
                        }
                        files {
                            ... on SanityVideo {
                                _key
                                _type
                                thumbnail {
                                  alt
                                  asset {
                                    url
                                  }
                                }
                                file {
                                  asset {
                                    url
                                    mimeType
                                    originalFilename
                                  }
                                }
                                region
                            }                          
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                asset{
                                    url
                                    mimeType
                                    originalFilename
                                }
                            }
                        }
                        region
                        sectionDetailPages {
                            title
                            slug {
                                current
                            }
                            region
                        }
                    }
                    ...SanityCommonSection

                }
            }
        }

        setting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }

    }
`


const BuildingLanding = props => {
    const {data, errors} = props
    // console.log('-----------> data: ', data, props)

    const userInfo = useSelector(state => state.userInfo)

    let userRegion = userInfo ? userInfo.region : ''


    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const building = (data || {}).building
    if (!building) {
        throw new Error(
        'Missing "buildingLading". Open the studio at http://localhost:3333 and add some content to "buildingLading" and restart the development server.'
        )
    }

    let  banner = building.banner && building.banner.asset ? building.banner.asset.url : ''
    let setting = (data || {}).setting

    return (
        <Layout>
            <SEO
                title={building.title}
                description={setting.description}
                keywords={setting.keywords}
            />


            {/* image */}
            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={building.banner ? building.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className="mb-0" style={{color: 'rgb(245, 235, 220)'}}>{building.title}</h1>
                    </div>
                </div> */}
            </div>
            </div>

            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Building</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>

            
            {/* content */}
            <section className="pt-0 pb-2 mb-4">
                <div className="container">
                    <div className="row d-flex justify-content-lg-between">
                        
                        <div className="col-md-6">
                            {building.pageImage && building.pageImage.asset && (
                                <img
                                    src={imageUrlFor(buildImageObj(building.pageImage))
                                        .fit('crop')
                                        .url()}
                                    alt={building.pageImage.alt}
                                />
                            )}
                        </div>

                        <div className="col-md-6">
                            <h3>{building.subTitle}</h3>
                            {building._rawPageDescription && <PortableText blocks={building._rawPageDescription} />}

                            {building.mainReferences && <MainReference items={building.mainReferences} userRegion={userRegion} />}
                        </div>
                    </div>
                </div>
            </section>

            {/* accordion */}
            <section className="pt-0 pb-2 mb-4">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 mb-12">
                            <Accordion items={building.categories} userRegion={userRegion} />
                        </div>

                    </div>
                </div>
            </section>                

        </Layout>
    )
}

export default BuildingLanding